<template lang="html">
  <svg style="width: 56px; height: 56px;" height="88px" class="loader-svg" viewBox="25 25 50 50" xmlns="http://www.w3.org/2000/svg">
    <circle class="loader-path" cx="50" cy="50" r="20" fill="none" stroke="#E4EAEF" stroke-width="2"/>
  </svg>
</template>

<script>
export default {
  name: 'IconPreloader'
}
</script>

<style lang="scss" scoped>
  .loader-svg {
    animation: rotate 2s linear infinite;
    height: 56px;
    width: 56px;
    transform-origin: center center;
  }

  .loader-path {
    animation: dash 1.5s ease infinite, color 6s ease infinite;
    stroke-dasharray: 150, 200;
    stroke-linecap: round;
    stroke-dashoffset: -10;
  }

  @-webkit-keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }

  @-webkit-keyframes dash {
    0% {
      stroke-dashoffset: 0;
      stroke-dasharray: 1, 200;
    }
    50% {
      stroke-dashoffset: -35;
      stroke-dasharray: 89, 200;
    }
    100% {
      stroke-dashoffset: -124;
      stroke-dasharray: 89, 200;
    }
  }

  @keyframes dash {
    0% {
      stroke-dasharray: 1, 200;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 89, 200;
      stroke-dashoffset: -35;
    }
    100% {
      stroke-dasharray: 89, 200;
      stroke-dashoffset: -124;
    }
  }

  @-webkit-keyframes color {
    80%, 90% {
      stroke: #E4EAEF;
    }
  }

  @keyframes color {
    80%, 90% {
      stroke: #E4EAEF;
    }
  }
</style>
