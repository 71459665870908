<template lang="html">
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
        d="M11.638 7.52863C11.8983 7.78896 11.8983 8.21096 11.638 8.47129L5.30467 14.8046C5.04433 15.065 4.62233 15.065 4.362 14.8046C4.10167 14.5443 4.10167 14.1223 4.362 13.862L10.224 7.99996L4.362 2.13796C4.23167 2.00763 4.16667 1.8373 4.16667 1.66663C4.16667 1.49596 4.23167 1.32563 4.362 1.1953C4.62233 0.934962 5.04433 0.934962 5.30467 1.1953L11.638 7.52863Z"
        fill="currentColor"/>
  </svg>
</template>

<script>
export default {
  name: 'IconChevron'
}
</script>
