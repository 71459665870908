<template lang="pug">
  .input-otp-component(:class="classList")
    .otp-container(v-for="(input, i) in quantity" :key="i")
      input(
        @paste="onPaste"
        @input.number="onInput"
        @focus.stop="updateActiveElId"
        @keypress.stop="updateActiveElId"
        @keydown.left="pressLeft"
        @keydown.right="pressRight"
        @keydown.delete="pressDelete"
        maxlength="1"
        :autofocus="hasAutofocus(i)"
        :data-id="i"
        :ref="`input-${i}`"
        :disabled="disabled || loading"
        type="text")

      transition(name="fade")
        .preloader(v-if="loading")
</template>

<script>
export default {
  name: 'InputOtp',
  model: {
    prop: 'value',
    event: 'input'
  },
  props: {
    value: {
      type: String,
      default: ''
    },
    quantity: {
      type: [String, Number],
      default: 5
    },
    loading: Boolean,
    disabled: Boolean,
    error: Boolean,
    autofocus: Boolean
  },
  data () {
    return {
      id: 0
    }
  },
  computed: {
    classList () {
      return {
        disabled: this.disabled,
        loading: this.loading,
        error: !!this.error,
        'transition-error': !!this.error
      }
    }
  },
  methods: {
    resetCode () {
      for (let i = 0; i < this.quantity; i++) {
        this.$refs[`input-${i}`][0].value = ''
      }

      if (this.$refs['input-0'] && this.$refs['input-0'][0] && this.autofocus) {
        setTimeout(() => {
          this.$refs['input-0'][0].focus()
        }, 100)
      }

      this.$emit('input', '')
    },
    onPaste (e) {
      e.preventDefault()

      let clipboardData = e.clipboardData || window.clipboardData
      clipboardData = clipboardData.getData('Text')
      clipboardData = clipboardData.split('')
      clipboardData = clipboardData.map(item => {
        const char = Number(item)
        if (!isNaN(char)) {
          return char
        } else {
          return ''
        }
      })

      clipboardData.forEach((char, i) => {
        if (i < this.quantity) {
          if (clipboardData[i] !== undefined && String(clipboardData[i])) {
            this.$refs[`input-${i}`][0].value = ''
            this.$refs[`input-${i}`][0].value = clipboardData[i]
          }
        }
      })

      this.$emit('onPaste')
      this.updateValue()
    },
    hasAutofocus (i) {
      return !i && this.autofocus
    },
    updateActiveElId (e) {
      const charCode = (e.which) ? e.which : e.keyCode
      if (charCode && (charCode < 48 || charCode > 57)) {
        e.preventDefault()
        return
      }
      this.id = Number(e.target.dataset.id)
      e.target.setSelectionRange(0, 2)
    },
    onInput (e) {
      if (/\D/.test(e.target.value)) {
        e.preventDefault()
        return
      }

      if (!e.target.value) {
        if (this.id) {
          const el = this.$refs[`input-${this.id - 1}`][0]
          this.id = Number(el.dataset.id)
          this.focusOnEl(el)
        }
        e.preventDefault()
        this.updateValue()
        return
      }

      const quantity = Number(this.quantity)

      if (this.id < quantity - 1) {
        const el = this.$refs[`input-${this.id + 1}`][0]
        this.id = Number(el.dataset.id)
        this.focusOnEl(el)
      }

      this.updateValue()
    },
    pressLeft (e) {
      if (e.key !== 'ArrowLeft') return

      if (this.id) {
        const el = this.$refs[`input-${this.id - 1}`][0]
        this.id = Number(el.dataset.id)
        this.focusOnEl(el)
      } else {
        this.focusOnEl(e.target)
      }
    },
    pressRight (e) {
      if (e.key !== 'ArrowRight') return

      if (this.id < this.quantity - 1) {
        const el = this.$refs[`input-${this.id + 1}`][0]
        this.id = Number(el.dataset.id)
        this.focusOnEl(el)
      } else {
        this.focusOnEl(e.target)
      }
    },
    pressDelete (e) {
      if (e.target.value) return
      if (e.key !== 'Backspace' || !this.id) return

      const el = this.$refs[`input-${this.id - 1}`][0]
      this.id = Number(el.dataset.id)
      this.focusOnEl(el)
    },
    updateValue () {
      let inputsValue = []

      for (let i = 0; i < this.quantity; i++) {
        inputsValue.push(this.$refs[`input-${i}`][0].value)
      }
      inputsValue = inputsValue.join('')

      this.$emit('input', inputsValue)

      if (inputsValue.length === this.quantity) {
        this.$emit('onComplete', inputsValue)
      }
    },
    focusOnEl (el) {
      el.focus()
      setTimeout(() => {
        el.setSelectionRange(0, 2)
        el.select()
      }, 50)
    },
    changeToNumType () {
      for (let i = 0; i < this.quantity; i++) {
        this.$refs[`input-${i}`][0].setAttribute('type', 'tel')
      }
    }
  },
  mounted () {
    for (let i = 0; i < this.quantity; i++) {
      const el = this.$refs[`input-${i}`][0]
      el.addEventListener('touchstart', this.changeToNumType)
    }

    this.id = 0

    if (this.value) {
      let valueArr = this.value.split('')
      if (valueArr.length > this.quantity) {
        valueArr.length = this.quantity
      }

      valueArr = valueArr.map(value => {
        let num = Number(value)
        if (isNaN(num)) num = ''
        return num
      })

      for (let i = 0; i < valueArr.length; i++) {
        this.$refs[`input-${i}`][0].value = valueArr[i]
      }

      if (this.autofocus) {
        setTimeout(() => {
          this.$refs['input-0'][0].focus()
        }, 100)
      }
    }
  },
  beforeDestroy () {
    this.id = 0
  }
}
</script>

<style lang="scss" scoped>
  .input-otp-component {
    display: flex;

    &.disabled .otp-container input {
      cursor: default;
      background-color: $color-gray-10;
      box-shadow: 0 0 0 1px $color-gray-30 inset;
    }

    &.error .otp-container input {
      box-shadow: 0 0 0 2px #FF665C inset;
    }

    .otp-container {
      position: relative;

      & + .otp-container {
        margin-left: 8px;
      }

      .preloader {
        @include preloader();
        left: 1px;
        width: calc(100% - 2px);
        height: calc(100% - 2px);
        border-radius: 4px;
      }

      input {
        display: block;
        height: 48px;
        width: 48px;
        padding: 9px 12px;
        text-align: center;
        box-shadow: 0 0 0 1px $color-gray-40 inset;
        font-weight: 500;
        font-size: 22px;
        line-height: 30px;
        border: 0;
        border-radius: 4px;
        outline: none;
        background-color: $color-white;
        transition: box-shadow 0.1s ease;
        caret-color: transparent;
        cursor: pointer;
        -webkit-appearance: none;

        &:focus {
          box-shadow: 0 0 0 2px $color-light-blue-100 inset;
        }

        &:disabled {
          cursor: default;
        }

        &::selection {
          background-color: transparent;
        }
      }
    }

    @include transition-error()
  }
</style>
