import api from '@/api/index'

/**
 * @param id {string}
 * @returns {Promise<*>}
 */
export const getChannelsInfo = (id) => {
  return api.get(`/io/ext/api/v1/verifier/widget/ui/${id}`)
}

/**
 * @param key {string}
 * @returns {Promise<*>}
 */
export const getVerifierStatus = (key) => {
  return api.get(`/io/ext/api/v1/verifier/widget/ui/check/${key}`)
}

/**
 * @param data {object}
 * @returns {Promise<*>}
 */
export const postClientInfo = (data) => {
  return api.post('/io/ext/api/v1/verifier/widget/ui/send', data)
}

/**
 * @param data {object}
 * @param options {object}
 * @returns {Promise<*>}
 */
export const postClientInfoFromTilda = (data, options = {}) => {
  return api.post('/io/ext/api/v1/verifier/widget/tilda/send', data, options)
}

/**
 * @param data {object}
 * @returns {Promise<*>}
 */
export const postVerificationCode = (data) => {
  return api.post('/io/ext/api/v1/verifier/widget/ui/check', data)
}

export default {
  postClientInfo,
  getChannelsInfo
}
