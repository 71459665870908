<template lang="pug">
  .confirmation-message-component
    span(v-if="selectedChannel.value === 'VK'") В аккаунт VK, привязанный к <strong>{{destination}}</strong>,<br>отправлен код подтверждения
    span(v-if="selectedChannel.value === 'VIBER'") В аккаунт Viber, привязанный к <strong>{{destination}}</strong>,<br>отправлен код подтверждения
    span(v-if="['WHATSAPP', 'WEASY'].includes(selectedChannel.value)") В аккаунт WhatsApp, привязанный к <strong>{{destination}}</strong>, отправлен код подтверждения
    span(v-if="selectedChannel.value === 'VOICECODE'") На номер <strong>{{destination}}</strong> поступит звонок, возьмите трубку, прослушайте и введите код
    span(v-if="selectedChannel.value === 'FLASHCALL'") На номер <strong>{{destination}}</strong> поступит звонок – введите последние цифры звонящего номера в качестве кода
    span(v-if="selectedChannel.value === 'SMS'") На номер <strong>{{destination}}</strong> отправлено SMS с&nbsp;кодом подтверждения
</template>

<script>
export default {
  name: 'ConfirmationMessage',
  props: {
    selectedChannel: {
      type: Object,
      default: () => ({})
    },
    destination: {
      type: String,
      default: ''
    }
  },
  computed: {
    isRuDestination () {
      if (!this.destination) return true

      const destinationNum = parseInt(this.destination)

      if (!isNaN(destinationNum)) {
        return destinationNum.toString()[0] === '7'
      }

      return true
    }
  }
}
</script>

<style lang="scss" scoped>
  .confirmation-message-component {
    text-align: center;
    margin-bottom: 12px;

    span {
      font-weight: 300;
      font-size: 14px;
      line-height: 20px;

      strong {
        font-size: 14px;
        line-height: 20px;
        font-family: 'Euclid Circular A', sans-serif;
        font-weight: 500;
      }

      ::v-deep strong {
        font-family: 'Euclid Circular A', sans-serif;
        font-weight: 500;
      }
    }
  }
</style>
