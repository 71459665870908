<template lang="html">
  <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
        d="M50.1217 10.4776C49.5155 10.4956 48.9401 10.749 48.5175 11.184L19.8339 39.8675L7.48367 27.5173C7.26867 27.2934 7.01114 27.1146 6.72617 26.9914C6.44121 26.8682 6.13453 26.8032 5.8241 26.8C5.51367 26.7969 5.20574 26.8557 4.91832 26.973C4.63091 27.0904 4.3698 27.2639 4.15028 27.4834C3.93077 27.7029 3.75726 27.964 3.63991 28.2514C3.52257 28.5388 3.46376 28.8468 3.46692 29.1572C3.47008 29.4676 3.53514 29.7743 3.65831 30.0593C3.78147 30.3442 3.96026 30.6018 4.18419 30.8168L18.1842 44.8168C18.6218 45.2542 19.2152 45.4999 19.8339 45.4999C20.4527 45.4999 21.0461 45.2542 21.4837 44.8168L51.817 14.4834C52.1542 14.1557 52.3845 13.7337 52.4777 13.2728C52.5709 12.812 52.5228 12.3337 52.3395 11.9006C52.1563 11.4676 51.8465 11.1 51.4507 10.8461C51.0549 10.5922 50.5917 10.4637 50.1217 10.4776Z"
        fill="#4DC247"/>
  </svg>
</template>

<script>
export default {
  name: 'IconSuccess'
}
</script>
