<template lang="html">
  <svg width="88" height="88" viewBox="0 0 88 88" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="88" height="88" rx="44" fill="#4DC247"/>
    <path
        d="M44 24C32.97 24 24 32.97 24 44C24 47.19 24.77 50.34 26.23 53.17L24.09 60.83C23.85 61.7 24.1 62.63 24.73 63.27C25.21 63.74 25.85 64 26.5 64C26.73 64 26.95 63.97 27.17 63.91L34.83 61.77C37.66 63.23 40.82 64 44 64C55.03 64 64 55.03 64 44C64 32.97 55.03 24 44 24ZM54.36 51.37C53.92 52.6 51.76 53.79 50.79 53.88C49.82 53.97 48.91 54.32 44.45 52.56C39.07 50.44 35.67 44.93 35.41 44.57C35.14 44.22 33.25 41.71 33.25 39.1C33.25 36.5 34.62 35.22 35.1 34.69C35.59 34.16 36.16 34.03 36.51 34.03C36.87 34.03 37.22 34.03 37.53 34.04C37.9 34.06 38.32 34.08 38.72 34.96C39.19 36 40.22 38.62 40.35 38.89C40.48 39.15 40.57 39.46 40.39 39.81C40.22 40.16 40.13 40.38 39.86 40.69C39.6 41 39.31 41.38 39.07 41.62C38.81 41.88 38.53 42.17 38.84 42.7C39.15 43.23 40.21 44.96 41.78 46.36C43.8 48.16 45.5 48.72 46.03 48.99C46.56 49.25 46.87 49.21 47.18 48.85C47.49 48.5 48.5 47.31 48.86 46.78C49.21 46.25 49.56 46.34 50.05 46.52C50.53 46.69 53.13 47.97 53.66 48.24C54.19 48.5 54.54 48.63 54.67 48.85C54.8 49.07 54.8 50.13 54.36 51.37Z"
        fill="white"/>
  </svg>
</template>

<script>
export default {
  name: 'IconWhatsApp'
}
</script>
