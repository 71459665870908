import Vue from 'vue'
import qs from 'qs'

class API {
  constructor () {
    this.baseUrl = 'https://direct.i-dgtl.ru'
  }

  /**
   * @param method {string}
   * @param url {string}
   * @param payload {object}
   * @returns {Promise<any>}
   */
  async request (method, url = '', payload = {}) {
    if (!url || typeof url !== 'string') return

    let payloadParams = ''
    let baseUrl

    if (payload?.params && Object.keys(payload.params).length) {
      payloadParams = '?' + qs.stringify(payload.params, { arrayFormat: 'repeat' })
    }

    if (Vue.prototype.$_customBaseUrl) {
      baseUrl = Vue.prototype.$_customBaseUrl
    } else {
      baseUrl = this.baseUrl
    }

    // eslint-disable-next-line no-useless-catch
    try {
      const response = await fetch(baseUrl + url + payloadParams, {
        method,
        headers: {
          ...(['POST', 'OPTIONS'].includes(method)) && { 'Content-Type': 'application/json' },
          ...(payload?.options?.headers) && { ...payload.options.headers }
        },
        ...(payload.body) && { body: payload.body }
      })

      if (response.ok) {
        return response.json()
      } else {
        throw await response.json()
      }
    } catch (err) {
      throw err
    }
  }

  /**
   * @param url {string}
   * @param options {object}
   * @returns {Promise<*>}
   */
  get (url, options = {}) {
    return this.request('GET', url, options)
  }

  /**
   * @param url {string}
   * @param body {object}
   * @param options {object}
   * @returns {Promise<*>}
   */
  post (url, body, options = {}) {
    return this.request('POST', url, {
      body: JSON.stringify(body),
      options
    })
  }

  /**
   * @param url {string}
   * @param body {object}
   * @param options {object}
   * @returns {Promise<*>}
   */
  put (url, body, options = {}) {
    return this.request('PUT', url, {
      ...options,
      body: JSON.stringify(body)
    })
  }

  /**
   * @param url {string}
   * @param body {object}
   * @param options {object}
   * @returns {Promise<*>}
   */
  delete (url, body, options = {}) {
    return this.request('DELETE', url, {
      ...options,
      body: JSON.stringify(body)
    })
  }
}

export { API }
export default new API()
