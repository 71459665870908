<template lang="html">
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
        d="M6.00289 5.00043C5.80437 5.00049 5.61037 5.05976 5.4457 5.17068C5.28104 5.2816 5.15318 5.43912 5.07847 5.62311C5.00377 5.80709 4.98562 6.00919 5.02634 6.20355C5.06706 6.39791 5.1648 6.5757 5.30707 6.71421L10.5891 11.9979L5.30707 17.2815C5.21129 17.3735 5.13483 17.4837 5.08215 17.6056C5.02947 17.7275 5.00165 17.8587 5.0003 17.9915C4.99895 18.1243 5.0241 18.256 5.07428 18.379C5.12447 18.502 5.19868 18.6137 5.29256 18.7076C5.38645 18.8015 5.49812 18.8757 5.62104 18.9259C5.74397 18.9761 5.87567 19.0013 6.00843 18.9999C6.1412 18.9986 6.27236 18.9708 6.39424 18.9181C6.51611 18.8654 6.62625 18.7889 6.71821 18.6931L12.0002 13.4094L17.2823 18.6931C17.3742 18.7889 17.4844 18.8654 17.6062 18.9181C17.7281 18.9708 17.8593 18.9986 17.9921 18.9999C18.1248 19.0013 18.2565 18.9761 18.3794 18.9259C18.5024 18.8757 18.614 18.8015 18.7079 18.7076C18.8018 18.6137 18.876 18.502 18.9262 18.379C18.9764 18.256 19.0015 18.1243 19.0002 17.9915C18.9988 17.8587 18.971 17.7275 18.9183 17.6056C18.8657 17.4837 18.7892 17.3735 18.6934 17.2815L13.4114 11.9979L18.6934 6.71421C18.8376 6.57399 18.9361 6.39347 18.976 6.1963C19.0159 5.99913 18.9953 5.7945 18.9169 5.60924C18.8385 5.42399 18.706 5.26673 18.5367 5.1581C18.3675 5.04946 18.1694 4.9945 17.9684 5.00043C17.7091 5.00816 17.463 5.11655 17.2823 5.30264L12.0002 10.5863L6.71821 5.30264C6.62521 5.20701 6.514 5.131 6.39114 5.07909C6.26827 5.02719 6.13626 5.00044 6.00289 5.00043Z"
        fill="currentColor"/>
  </svg>
</template>

<script>
export default {
  name: 'IconCross'
}
</script>
