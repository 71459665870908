<template lang="pug">
  #idgtl-2fa-widget(v-if="showWidget")
    WidgetComponent
</template>

<script>
import WidgetComponent from '@/components/widget'

export default {
  name: 'App',
  components: { WidgetComponent },
  data () {
    return {
      showWidget: true
    }
  },
  methods: {
    async removeWidget () {
      this.showWidget = false

      await this.$nextTick()

      if (this.$el.parentNode) {
        const widgetElement = this.$el.parentNode.querySelector('#idgtl-2fa-widget')
        if (widgetElement) widgetElement.remove()
      }
    }
  }
}
</script>

<style lang="scss">
  #idgtl-2fa-widget {
    font-family: 'Euclid Circular A', sans-serif;

    * {
      box-sizing: border-box;
      font-style: inherit;
      font-variant: tabular-nums;
      text-rendering: optimizeLegibility;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      -moz-font-feature-settings: "liga" on;
      font-family: 'Euclid Circular A', sans-serif;
    }

    button {
      padding: 0;
      border: 0;
      background-color: transparent;
      cursor: pointer;
    }
  }
</style>
