<template lang="html">
  <svg width="88" height="88" viewBox="0 0 88 88" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="88" height="88" rx="44" fill="#F5B740"/>
    <path
      d="M30.0581 26.0195C27.7981 26.0195 25.9181 27.9002 26.0581 30.1602C26.5881 38.6982 30.2668 46.3767 35.9448 52.0547C41.6228 57.7327 49.3014 61.4114 57.8394 61.9414C60.0994 62.0814 61.98 60.2014 61.98 57.9414V54.4805C61.98 52.4405 60.4609 50.74 58.4409 50.5L53.398 49.9219C52.178 49.7819 50.9806 50.1986 50.1206 51.0586L46.4214 54.7617C43.5834 53.3217 41.0087 51.4408 38.7847 49.2148C36.5587 46.9908 34.6778 44.4161 33.2378 41.5781L36.9409 37.8789C37.8009 37.0189 38.2177 35.8216 38.0777 34.6016L37.4995 29.5586C37.2595 27.5386 35.5591 26.0195 33.5191 26.0195H30.0581ZM46.1558 26.125C45.0098 25.991 43.9995 26.893 43.9995 28.043V28.2109C43.9995 29.1629 44.6981 29.9877 45.6441 30.0977C49.0981 30.5017 52.1703 32.1614 54.3863 34.6094C56.2703 36.6934 57.538 39.3472 57.898 42.2812C58.018 43.2673 58.8668 44 59.8628 44H59.8667C61.0667 44 62.0147 42.9478 61.8667 41.7578C60.8507 33.5798 54.3458 27.101 46.1558 26.125ZM45.9448 34.2305C44.9053 34.2229 43.9995 35.0608 43.9995 36.1562V36.3203C43.9995 37.2363 44.6585 37.9671 45.5425 38.2031C46.5285 38.4651 47.4143 38.9703 48.1323 39.6523C48.9123 40.3943 49.4966 41.3441 49.7886 42.4141C50.0386 43.3241 50.7938 44 51.7378 44C53.0438 44 54.0091 42.764 53.6831 41.5C52.7691 37.952 49.9581 35.1671 46.3941 34.2891C46.2423 34.2518 46.0934 34.2316 45.9448 34.2305Z"
      fill="white"/>
  </svg>
</template>

<script>
export default {
  name: 'IconConfirmationCall'
}
</script>
