<template lang="pug">
  .confirmation-section-component
    .selected-channel-icon
      component(:is="getSelectedChannelIcon")

    ConfirmationMessage(
      :selected-channel="selectedChannel"
      :destination="widgetParams.destination")

    .input-otp
      InputOtp(
        v-model="confirmationCode"
        @onComplete="onCodeInputComplete"
        :quantity="selectedChannel.codeLength"
        :disabled="['EXPIRED', 'TOO_MANY_REQUESTS'].includes(confirmationStatus)"
        :loading="loading"
        autofocus
        ref="otp")

    .countdown
      span.error-message(v-if="getErrorMessage") {{ getErrorMessage }}
      span(v-if="loading") Проверка кода...
      span(v-if="timeoutSeconds > 0 && !loading") Запросить код {{ timeoutSeconds }} сек
      button(
        v-if="timeoutSeconds <= 0 && !loading"
        @click="requestCodeAgain") Запросить код
</template>

<script>
import ConfirmationMessage from '@/components/confirmation-section/confirmation-message.vue'
import InputOtp from '@/components/input-otp'
import IconVK from '@/components/icons/IconVK'
import IconWhatsApp from '@/components/icons/IconWhatsApp'
import IconSMS from '@/components/icons/IconSMS'
import IconViber from '@/components/icons/IconViber.vue'
import IconConfirmationCall from '@/components/icons/IconConfirmationCall'

export default {
  name: 'ConfirmationSection',
  components: {
    ConfirmationMessage,
    InputOtp,
    IconVK,
    IconWhatsApp,
    IconSMS,
    IconViber,
    IconConfirmationCall
  },
  props: {
    widgetParams: {
      type: Object,
      default: () => ({})
    },
    selectedChannel: {
      type: Object,
      default: () => ({})
    },
    confirmationStatus: {
      type: String,
      default: ''
    },
    loading: Boolean
  },
  data () {
    return {
      hasConfirmationError: false,
      confirmationCode: '',
      timeoutSeconds: 30
    }
  },
  watch: {
  },
  computed: {
    getSelectedChannelIcon () {
      return this.selectedChannel ? this.selectedChannel?.icon || null : null
    },
    getErrorMessage () {
      switch (this.confirmationStatus) {
        case 'WRONG_CODE':
          return 'Неверный код'
        case 'EXPIRED':
          return 'Время жизни кода истекло'
        case 'UNSENT':
          return 'Не получилось отправить код'
        case 'TOO_MANY_REQUESTS':
          return 'Превышено количество обращений к сервису'
      }

      return ''
    }
  },
  methods: {
    onCodeInputComplete () {
      this.$emit('onCodeInputComplete', this.confirmationCode)
    },
    resetCountdown () {
      clearInterval(this.waitTimeout)

      this.timeoutSeconds = 30

      this.waitTimeout = setInterval(() => {
        this.timeoutSeconds--
        if (this.timeoutSeconds === 0) clearInterval(this.waitTimeout)
      }, 1000)
    },
    requestCodeAgain () {
      this.$emit('onRequestCodeAgain')
    },
    resetCode () {
      this.$refs.otp.resetCode()
    }
  },
  mounted () {
    this.resetCountdown()
  }
}
</script>

<style lang="scss" scoped>
  .confirmation-section-component {
    width: 100%;

    .selected-channel-icon {
      width: 56px;
      height: 56px;
      margin: 0 auto 8px auto;

      svg {
        width: 100%;
        height: 100%;
      }
    }

    .input-otp {
      display: flex;
      justify-content: center;
      margin-bottom: 10px;
    }

    .countdown {
      text-align: center;

      span {
        display: block;
        font-style: normal;
        font-weight: 300;
        font-size: 14px;
        line-height: 24px;
      }

      .error-message {
        color: #FF5449;
      }

      button {
        font-size: 14px;
        line-height: 20px;
        color: #009CE5;
        transition: color 0.2s ease;

        &:hover {
          transition: color 0s ease;
          color: #FF5449;
        }
      }
    }
  }
</style>
