<template lang="html">
  <svg width="88" height="88" viewBox="0 0 88 88" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="88" height="88" rx="44" fill="#1A85FF"/>
    <path
        d="M45.9649 57.0769C30.9318 57.0769 22.3573 46.9129 22 30H29.5303C29.7776 42.4136 35.329 47.6718 39.7263 48.756V30H46.8171V40.7061C51.1594 40.2453 55.721 35.3666 57.2601 30H64.3508C63.169 36.6134 58.2221 41.4921 54.7043 43.4978C58.2221 45.124 63.8563 49.3794 66 57.0769H58.1946C56.5182 51.9272 52.3412 47.9429 46.8171 47.4008V57.0769H45.9649Z"
        fill="white"/>
  </svg>
</template>

<script>
export default {
  name: 'IconVK'
}
</script>
