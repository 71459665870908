<template lang="pug">
  .channel-section-component
    section.pick-channel-section
      .section-title
        span Выберите способ подтверждения <br> номера телефона {{ widgetParams.destination }}

      .channels
        ButtonChannel(
          v-for="(channel, i) in channels"
          @click="onChannelClick"
          :key="i"
          :channel="channel")

      .idgtl-logo-link#idgtl-logo-link
        a(href="https://i-dgtl.ru/" target="_blank")
          IconLogo
</template>

<script>
import ButtonChannel from '@/components/channel-button/index.vue'
import IconLogo from '@/components/icons/IconLogo'

export default {
  name: 'ChannelSection',
  components: { ButtonChannel, IconLogo },
  props: {
    widgetParams: {
      type: Object,
      default: () => ({})
    },
    channels: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    onChannelClick (channel) {
      this.$emit('onChannelSelect', channel)
    }
  }
}
</script>

<style lang="scss" scoped>
  .channel-section-component {
    display: flex;
    width: 100%;

    .pick-channel-section {
      width: 100%;

      .section-title {
        text-align: center;
        margin-bottom: 12px;

        span {
          font-weight: 400;
          font-size: 18px;
          line-height: 26px;
          font-style: normal;
        }
      }

      .idgtl-logo-link, #idgtl-logo-link {
        display: flex;
        margin-top: 10px;
        justify-content: center;

        a {
          color: $color-gray-60;

          &:hover {
            color: $color-gray-70
          }

          svg {
            vertical-align: middle;
            color: $color-gray-60;

            &:hover {
              color: $color-gray-70
            }
          }
        }
      }
    }

    @media (max-width: 479px) {
      .pick-channel-section {
        .channels {
          padding: 0;
        }
      }
    }
  }
</style>
