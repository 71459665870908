<template lang="html">
  <svg width="88" height="88" viewBox="0 0 88 88" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
        d="M43.701 88C67.5056 88 86.803 68.3005 86.803 44C86.803 19.6995 67.5056 0 43.701 0C19.8964 0 0.598938 19.6995 0.598938 44C0.598938 68.3005 19.8964 88 43.701 88Z"
        fill="#7F4DA0"/>
    <path fill-rule="evenodd" clip-rule="evenodd"
          d="M43.701 23.8333C33.2778 23.8333 22.15 25.2943 22.15 42.8415C22.15 52.1252 25.3625 57.8472 31.9453 60.289V64.7907C31.9557 66.4814 33.3057 67.844 34.9611 67.8333C35.8178 67.828 36.6312 67.4489 37.1962 66.7911L41.4872 61.8495H43.701C54.124 61.8495 65.252 60.3884 65.252 42.8415C65.252 25.2943 54.124 23.8333 43.701 23.8333ZM43.7012 37.8395C45.8654 37.8395 47.6199 39.631 47.6199 41.8409C47.6199 42.3935 47.1808 42.8413 46.6402 42.8413C46.0991 42.8413 45.6603 42.3935 45.6603 41.8409C45.6603 40.7363 44.7834 39.8398 43.7012 39.8398C43.1599 39.8398 42.7215 39.3919 42.7215 38.8395C42.7215 38.2875 43.1599 37.8395 43.7012 37.8395ZM43.7012 33.8375C43.1599 33.8375 42.7215 34.2854 42.7215 34.8377C42.7215 35.3907 43.1599 35.8384 43.7012 35.8384C46.9473 35.8384 49.579 38.5257 49.579 41.8409C49.579 42.3935 50.017 42.8413 50.5587 42.8413C51.0994 42.8413 51.538 42.3935 51.538 41.8409C51.538 37.4207 48.029 33.8375 43.7012 33.8375ZM53.4972 50.8444C53.0143 53.764 50.5188 55.8881 47.6199 55.8466C44.5436 55.8466 38.6272 52.0852 36.1583 49.5442C33.6898 47.003 29.987 40.9803 29.987 37.8395C29.946 34.8788 32.0255 32.3299 34.8845 31.8368H36.8442C37.2184 31.8358 37.5609 32.0533 37.7256 32.3972C37.7256 32.3972 38.9012 34.8377 39.6846 36.399C40.4683 37.959 38.9208 40.1804 37.9804 41.1407C38.5409 42.6296 39.3867 43.9907 40.4683 45.1424C41.5962 46.2469 42.9284 47.1104 44.387 47.6837C45.309 46.6004 46.5811 45.8905 47.9722 45.6826C48.3334 45.6828 48.6895 45.7723 49.0108 45.9426L52.929 47.9437C53.2655 48.1116 53.4783 48.4612 53.4774 48.8439L53.4972 50.8444ZM53.4972 41.8409C53.4972 42.3935 53.9357 42.8413 54.4769 42.8413C55.0174 42.8413 55.4562 42.3935 55.4562 41.8409C55.4562 35.2108 50.1934 29.836 43.7012 29.836C43.1599 29.836 42.7215 30.2839 42.7215 30.8365C42.7215 31.3892 43.1599 31.8368 43.7012 31.8368C49.1114 31.8368 53.4972 36.3156 53.4972 41.8409Z"
          fill="white"/>
  </svg>
</template>

<script>
export default {
  name: 'IconViber'
}
</script>
